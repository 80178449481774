<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { uploader } from "@/views/pages/email/UploadAdopter";
// import simplebar from "simplebar-vue";
import $ from 'jquery'
import axios from "axios";

const axios2 = axios.create({
    baseURL: 'https://dev-live.rydelinx.com/api/admin/email/',
    headers: {
      'x-api-key': 'Wx8Cc7rEDJXTcyPfzM65h5q0AKDjLXqb'
    }
  })

let emails = []



$(function ($) {
  $.fn.email_multiple = function (options) {
    let defaults = {
      reset: false,
      fill: false,
      data: null
    };
    let index = null
    let settings = $.extend(defaults, options);
    let email = "";

    return this.each(function () {
      $(this).after("<span class=\"to-input\">To :</span>\n" +
        "<div class=\"all-mail\"></div>\n" +
        "<input type=\"text\" name=\"email\" class=\"enter-mail-id\" />");
      let $orig = $(this);
      let $element = $('.enter-mail-id');
      $element.keydown(function (e) {
        $element.css('border', '');

        if (e.keyCode === 13 || e.keyCode === 32) {
          let getValue = $element.val();
          if (/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(getValue)) {
            settings.data.push(getValue)
            console.log(settings.data)
            $('.all-mail').append('<span class="email-ids">' + getValue + '<span class="cancel-email">x</span></span>');
            $element.val('');

            email += getValue + ';'
          } else {
            $element.css('border', '1px solid red')
          }
        }
        $orig.val(email.slice(0, -1))
      });

      $(document).on('click', '.cancel-email', function () {
        index = settings.data.indexOf($(this).parent()[0].firstChild.data)
        settings.data.splice(index, 1)
        console.log(settings.data)
        $(this).parent().remove();
      });

      if (settings.reset) {
        $('.email-ids').remove()
      }

      return $orig.hide()
    });
  };

})

/**
 * Email-inbox component
 */
export default {

  page: {
    title: "Inbox",
    meta: [{ name: "description" }]
  },
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      mailModal: false,
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [uploader]
      },
      editorData: null,
      subject: null,
      to: [],
    };
  },


  methods: {
    

    sendMail() {
      
      for (let i = 0; i < emails.length; i++) {
        this.to.push({ email_address: emails[i] })
      }
      if (this.to.length > 0) {
        axios2.post('send-emails', { toArray: this.to, subject: this.subject, message: this.editorData }, {
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
          }
        }).then(() => {
          this.resetMailData()
          this.mailModal = false
          this.to = []
          emails = []
          this.$bvToast.toast('Mail Sent', {
            variant: 'success',
            solid: true
          })

        }).catch((err) => {
          console.log(err)
        })
      }
      else {
        this.$bvToast.toast('Enter recipient', {
          variant: 'danger',
          solid: true
        })
      }
    },


    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop =
            this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },

    texttoHtml(text) {
      return text.replace(/&lt;/g, '<')
    },


    resetMailData() {
      this.to = []
      emails = []
      this.subject = null
      this.editorData = null
    },
    

    toMailInput() {
      this.to = []
      emails = []
      $(document).ready(function ($) {
        $("#essai").email_multiple({
          data: emails
        });
      });

    },

   


  },
  mounted() {

    
    let container = document.querySelector(
      "#scrollElement .simplebar-content-wrapper"
    );
    container.scrollTo({ top: 200, behavior: "smooth" });

  },


};
</script>

<template>
  <div>

    <div class="email-leftbar card">
      <b-button variant="danger" @click="mailModal = true; toMailInput()">Compose</b-button>
      <div class="mail-list mt-4">
        <a href="javascript: void(0);"
          :class="{ active: this.$route.path === '/email/inbox' || this.$route.params.text === 'Inbox' }"
          @click="$router.push('/email/inbox')">
          <i class="mdi mdi-email-outline mr-2"></i> Inbox
          <!--              <span class="ml-1 float-right">(18)</span>-->
        </a>
        <a href="javascript: void(0);"
          :class="{ active: this.$route.path === '/email/starred' || this.$route.params.text === 'Starred' }"
          @click="$router.push('/email/starred')">
          <i class="mdi mdi-star-outline mr-2"></i>Starred
        </a>
        <a href="javascript: void(0);"
          :class="{ active: this.$route.path === '/email/sent_mail' || this.$route.params.text === 'Sent Mail' }"
          @click="$router.push('/email/sent_mail')">
          <i class="mdi mdi-email-check-outline mr-2"></i>Sent Mail
        </a>
        <a href="javascript: void(0);"
          :class="{ active: this.$route.path === '/email/replies' || this.$route.params.text === 'Replies' }"
          @click="$router.push('/email/replies')">
          <i class="mdi mdi-email-check-outline mr-2"></i>Replies
        </a>
        <a href="javascript: void(0);"
          :class="{ active: this.$route.path === '/email/trash' || this.$route.params.text === 'Trash' }"
          @click="$router.push('/email/trash')">
          <i class="mdi mdi-trash-can-outline mr-2"></i>Trash
        </a>
      </div>

    
    </div>

    <b-modal v-model="mailModal" ref="mailModal" title="New Message" centered>

      <form ref="mail">
        <div class="email-id-row form-group">
          <input type="email" class="form-control" id="essai" placeholder="To" />
        </div>

        <div class="form-group">
          <input type="text" class="form-control" placeholder="Subject" v-model="subject" />
        </div>
        <div class="form-group">
          <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="mailModal = false; resetMailData()">Close</b-button>
        <b-button variant="primary" @click="sendMail()">
          Send
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    
  </div>
</template>

<style>
span.email-ids {
  float: left;
  /* padding: 4px; */
  border: 1px solid #ccc;
  margin-right: 5px;
  margin-bottom: 5px;
  background: #f5f5f5;
  padding: 3px 10px;
  border-radius: 5px;
}

span.cancel-email {
  border: 1px solid #ccc;
  width: 18px;
  display: block;
  float: right;
  text-align: center;
  margin-left: 20px;
  border-radius: 49%;
  height: 18px;
  line-height: 15px;
  margin-top: 1px;
  cursor: pointer;
}

.email-id-row {
  border: 1px solid #ced4da;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: block;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  color: #505d69;
}

.email-id-row input {
  border: 0;
  outline: 0;
}

span.to-input {
  display: block;
  float: left;
  padding-right: 11px;
}

.email-id-row {
  padding-top: 6px;
  padding-bottom: 7px;
  /*margin-top: 23px;*/
}
</style>